import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as React from 'react'
import ReactMarkdown from 'react-markdown'
import Header from '../components/header'
import Layout from '../components/layout'
import NextPage from '../components/nextPage'
import SEO from '../components/seo'
import Spacer from '../components/spacer'

const Blog = ({ data: { datoCmsBlog, datoCmsNews } }) => {
	const { title, info, date, image, contentBlocks } = datoCmsBlog

	return (
		<Layout className='pth'>
			<SEO title={title || date} description={info} image={image?.gatsbyImageData?.images?.fallback?.src} />
			<Header page='News' />

			<div className='flex flex-column pl40 pr40 pt80 pb40 m-p20'>
				<div className=' flex flex-col ma'>
					<Spacer className='m-hide' />
					<p className='text-center m-mt40 op-50 mb20 max-650 ma'>{date}</p>
					<h1 className='mb40 small max-650 ma'>{info}</h1>

					{image?.gatsbyImageData && (
						<div className='mb40 max-900 ma'>
							<GatsbyImage image={image.gatsbyImageData} alt={info || date} className='w-100' />
						</div>
					)}

					<div className='content-blocks mt20 flex flex-col gap-40'>
						{contentBlocks?.map((block, index) => {
							if (block.__typename === 'DatoCmsImage' && block.image?.gatsbyImageData) {
								return (
									<div key={block.id || index} className='mb20 max-650 ma'>
										<GatsbyImage image={block.image.gatsbyImageData} alt={info || date} className='w-100 ' />
									</div>
								)
							}

							if (block.__typename === 'DatoCmsContent' && block.content) {
								return <ReactMarkdown key={block.id || index} className='flex flex-col gap-20 max-650 ma content-block' children={block.content} />
							}

							return null
						})}
						<ShareButton />
					</div>
				</div>
			</div>

			<Spacer />

			<div className='pt40 pb80'>
				<NextPage overline='Go back' title='News & Articles' link='/news' image={datoCmsNews?.image} />
			</div>
		</Layout>
	)
}

export default Blog

const ShareButton = () => {
	const [copied, setCopied] = React.useState(false)

	const handleCopy = () => {
		navigator.clipboard.writeText(window.location.href)
		setCopied(true)
		setTimeout(() => setCopied(false), 2000)
	}

	return (
		<button className='button h3 max-650 ma' onClick={handleCopy}>
			{copied ? 'Copied!' : 'Share Article'}
		</button>
	)
}

export const query = graphql`
	query BlogPostBySlug($id: String!) {
		datoCmsNews {
			image {
				gatsbyImageData
			}
		}
		datoCmsBlog(id: { eq: $id }) {
			id
			date
			info
			slug
			image {
				gatsbyImageData
			}
			contentBlocks {
				... on DatoCmsImage {
					id
					__typename
					image {
						gatsbyImageData
					}
				}
				... on DatoCmsContent {
					id
					__typename
					content
				}
			}
		}
	}
`
