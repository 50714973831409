import * as React from "react"
import Link from "../components/link"
import { GatsbyImage } from "gatsby-plugin-image"
import Spacer from "./spacer"

const NextPage = ({ image, title, link, overline='Next page' }) => {
  return (
    <div className="p40 pt0 pb0 ">
      <Link to={link} className='link '>
        <div className="max-1400 ma bt1 next--page">
          <div className="flex mt40 m-mt50">
            <div className="w-100 flex flex-wrap m-mh-80vh m-w-60">
              <p className='m0 h4'>{overline}</p>
              <div className="align-self--bottom w-100">
                <p className="m0 h1 mb20 m-mb50">{title}</p>
                <p className="button m0">Read more</p>
              </div>
            </div>
            <div className="mla w-100 max-180 m-max-100 ">
              <div className="ratio-2-1 overflow-hidden">
                <GatsbyImage className='bg-image' image={image?.gatsbyImageData} alt={title} />
              </div>
            </div>
          </div>
        </div>
        <Spacer className='m-show' />
      </Link>
    </div>
  )
}

export default NextPage
